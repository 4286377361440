<template>
  <el-main>
    <el-button style="margin-bottom: 10px" @click="add" size="small" type="primary" icon="el-icon-plus">添加表单</el-button>
    <!-- 表单 -->
    <el-form class="el-form-search" label-width="110px">
      <el-form-item label="搜索关键词：">
        <el-input size="small" placeholder="请输入搜索的关键词" v-model="searchForm.name"></el-input>
      </el-form-item>
      <el-form-item label="是否默认：">
        <el-select v-model="searchForm.is_default" size="small">
            <el-option label="全部" value="0"></el-option>
          <el-option label="否" value="1"></el-option>
          <el-option label="是" value="2"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label=" " label-width="20px">
        <el-button type="primary" size="small" @click="getList">搜索</el-button>
        <el-button type="text" size="small" @click="cancelSearch">清空搜索条件</el-button>
      </el-form-item>
    </el-form>
    <!-- 列表 -->
    <el-table :data="list" :header-cell-style="{ 'background-color': '#F8F9FA' }" :key="key">
      <template slot="empty">
        <No />
      </template>
      <el-table-column prop="name" label="模板名称" align="center"></el-table-column>
      <el-table-column prop="is_default" label="是否默认" align="center" width="240">
          <template v-slot="{ row }">
            <el-switch v-model="row.is_default_flag" @change="changeDefault(row.id)"></el-switch>
         </template>
      </el-table-column>
      <el-table-column prop="update_time" label="更新时间" align="center" width="280">
          <template v-slot="{ row }">
            {{row.update_time | getDateformat}}
         </template>
      </el-table-column>
      <el-table-column label="操作" width="240" align="center" fixed="right" >
        <template slot-scope="scope">
          <el-button @click="edit(scope.row)" type="text" size="small">编辑</el-button>
          <el-button @click="deleteData(scope.row.id)" type="text" size="small">删除</el-button>
          <el-button  type="text" size="small" @click="toDataList(scope.row.id)">表单收集</el-button>
          <el-popover style="margin-left: 10px" width="180" height="150px" placement="left" trigger="click">
              <el-image id="wxcode" :src="wxcode"></el-image>
              <el-image id="wxMiniCode" :src="wxMiniCode"></el-image>
              <el-button slot="reference" @click="actionsType(scope.row.id)" type="text" size="small">微信码</el-button>
            </el-popover>
        </template>
      </el-table-column>
    </el-table>

    <Paging :total="total_number" :page="searchForm.page" :pageNum="searchForm.rows" @updatePageNum="updateData"></Paging>
  </el-main>
</template>

<script>
import { getDateformat } from '@/util/getDate';
import Paging from '@/components/paging';
export default {
  components: {
    Paging,
  },
  data() {
    return {
        searchForm: {
            page: 1,
            rows: 10,
            name: '',
            is_default: '0',
        },
        total_number:0,
        key:'',
        wxcode:'',
        wxMiniCode:''
    };
  },
  filters:{
      getDateformat:getDateformat
  },
  created() {
    this.getList();
  },
  methods: {
    cancelSearch() {
        this.searchForm = {
            page: 1,
            rows: 10,
            name: '',
            is_default: '0',
        };
        this.getList();
    },
    getList() {
      this.$axios.post(this.$api.customform.list, this.searchForm).then(res => {
        if (res.code == 0) {
          this.total_number = res.result.total_number;
          let list = res.result.list
          list.forEach(item=>{
              item.is_default_flag = item.is_default=='1'
          })
          this.list = list;
          this.key = new Date()
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    add() {
        this.$router.push({path:'/extension/customform/addform'})
    },
    edit(row) {
        this.$router.push({path:'/extension/customform/editform',query:row})
    },
    deleteData(id){
        this.$confirm('确认删除此表单？', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning',
        })
        .then(() => {
            this.$axios.post(this.$api.customform.del, {id}).then(res => {
                if (res.code == 0) {
                    this.$message.success('删除成功');
                    this.getList();
                }else{
                    this.$message.error(res.msg);
                }
            });
        })
        .catch(() => {});
    },
    updateData(val, status) {
      if (status == 0) {
        this.searchForm.rows = val;
        this.getList();
      } else {
        this.searchForm.page = val;
        this.getList();
      }
    },
    changeDefault(id){
        this.$axios.post(this.$api.customform.setIsDefault, {id}).then(res => {
            if (res.code == 0) {
               this.getList();
            }else{
                this.$message.error(res.msg);
            }
        });
    },
    toDataList(id){
        this.$router.push({path:'/extension/customform/datalist',query:{form_id:id}})
    },
    actionsType(id){
      this.$axios.post(this.$api.customform.getWxcode, {id}).then(res => {
          if (res.code == 0) {
            this.wxcode = res.result
          }else{
              this.$message.error(res.msg);
          }
      });
      this.$axios.post(this.$api.customform.getWxMiniCode, {id}).then(res => {
          if (res.code == 0) {
            this.wxMiniCode = res.result
          }else{
              this.$message.error(res.msg);
          }
      });
    }
  },
};
</script>

<style lang="less" scoped>
.el-main {
  background: #fff;
}
.el-table {
  .userInfo {
    display: flex;
    align-items: center;

    .el-image {
      width: 50px;
      height: 50px;
      border-radius: 50%;
      margin-right: 10px;
    }
  }
}
</style>
